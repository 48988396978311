// extracted by mini-css-extract-plugin
export var letsBuildCommonStyles = "lets-build-module--letsBuildCommonStyles--9HjLh";
export var h2LetsBuildStyles = "lets-build-module--h2LetsBuildStyles--iqbUU";
export var formStyles = "lets-build-module--formStyles--UfCVI";
export var form = "lets-build-module--form--79ppP";
export var successMessage = "lets-build-module--successMessage--ss6He";
export var errorStyles = "lets-build-module--errorStyles--PxTeL";
export var formNameStyles = "lets-build-module--formNameStyles--gDiqX";
export var nameError = "lets-build-module--nameError--poEew";
export var formEmailStyles = "lets-build-module--formEmailStyles--Ng9f+";
export var emailError = "lets-build-module--emailError--mRoX7";
export var formCompanyStyles = "lets-build-module--formCompanyStyles--1yypr";
export var companyError = "lets-build-module--companyError--FLhWF";
export var formMessageStyles = "lets-build-module--formMessageStyles--r787d";
export var messageError = "lets-build-module--messageError--1S8pu";
export var formButtonStyles = "lets-build-module--formButtonStyles--EE4HH";