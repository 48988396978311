// extracted by mini-css-extract-plugin
export var visionStyles = "vision-matrix-module--visionStyles--A8LHF";
export var matrixLineStyles = "vision-matrix-module--matrixLineStyles--dFyDA";
export var matrixLine1 = "vision-matrix-module--matrixLine1--h1iQS";
export var matrixLine2 = "vision-matrix-module--matrixLine2--h-jZW";
export var matrixLine3 = "vision-matrix-module--matrixLine3--QC7eH";
export var matrixLine4 = "vision-matrix-module--matrixLine4--DCAl9";
export var matrixLine5 = "vision-matrix-module--matrixLine5--yGclb";
export var matrixLine6 = "vision-matrix-module--matrixLine6---rzs9";
export var matrixLine7 = "vision-matrix-module--matrixLine7--2Qx30";
export var rectangle1Styles = "vision-matrix-module--rectangle1Styles--dcGpQ";
export var rectangle2Styles = "vision-matrix-module--rectangle2Styles--g-ktR";
export var rectangle3Styles = "vision-matrix-module--rectangle3Styles--VZYJM";
export var rectangle4Styles = "vision-matrix-module--rectangle4Styles--857GV";
export var rectangle5Styles = "vision-matrix-module--rectangle5Styles--dSBN1";
export var rectangle6Styles = "vision-matrix-module--rectangle6Styles--nSNCg";
export var rectangle7Styles = "vision-matrix-module--rectangle7Styles--+KD7H";
export var rectangle8Styles = "vision-matrix-module--rectangle8Styles--QUPdg";
export var rectangle9Styles = "vision-matrix-module--rectangle9Styles--4-mQ+";
export var rectangle10Styles = "vision-matrix-module--rectangle10Styles--TzeO8";
export var rectangle11Styles = "vision-matrix-module--rectangle11Styles--4fdmJ";
export var rectangle12Styles = "vision-matrix-module--rectangle12Styles--zqK-U";
export var rectangle13Styles = "vision-matrix-module--rectangle13Styles--SI6Vn";
export var rectangle14Styles = "vision-matrix-module--rectangle14Styles--s5ADq";
export var rectangle15Styles = "vision-matrix-module--rectangle15Styles--Y2a3g";
export var binaryStyles = "vision-matrix-module--binaryStyles--poxUD";
export var binaryScroller1Styles = "vision-matrix-module--binaryScroller1Styles--1p2Rm";
export var binaryScroller2Styles = "vision-matrix-module--binaryScroller2Styles--kaWXG";
export var binaryScroller3Styles = "vision-matrix-module--binaryScroller3Styles--01oQ5";
export var binaryScroller4Styles = "vision-matrix-module--binaryScroller4Styles--dRslW";
export var binaryScroller5Styles = "vision-matrix-module--binaryScroller5Styles--2OTnq";
export var binaryScroller6Styles = "vision-matrix-module--binaryScroller6Styles--22vjm";
export var binaryScroller7Styles = "vision-matrix-module--binaryScroller7Styles--0eHq9";
export var rectangleStyles = "vision-matrix-module--rectangleStyles--ye-wm";