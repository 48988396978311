import React, { useRef, useState, useLayoutEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import queryString from "query-string";

import gsap from "gsap";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";

import { technoOrange, h3InnerStyles } from "../styles/global.module.css";
import {
  form,
  h2LetsBuildStyles,
  formStyles,
  formNameStyles,
  formEmailStyles,
  formCompanyStyles,
  formMessageStyles,
  formButtonStyles,
  letsBuildCommonStyles,
  errorStyles,
  nameError,
  emailError,
  companyError,
  messageError,
  successMessage,
} from "../styles/lets-build.module.css";

gsap.registerPlugin(ScrambleTextPlugin);

function LetsBuild({ getStandAloneTimelines, gridArea }) {
  const [submitted, setSubmitted] = useState(null);
  const tlLetsBuild = useRef();
  const tlSuccess = useRef();
  tlLetsBuild.current = gsap.timeline();
  tlSuccess.current = gsap.timeline();
  const config = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };
  const letsBuildStyles = {
    gridArea: gridArea,
  };

  useLayoutEffect(() => {
    if (submitted) {
      tlSuccess.current
        .to("#lets-build form", { duration: 0.5, display: "none" })
        .to("#success", { display: "block" })
        .to("#success", {
          duration: 1,
          opacity: 1,
          scrambleText: {
            text: "Thank you! We'll have our people contact your people.",
            chars: "!/&%{}]?#()",
          },
        });
    } else if (submitted === false) {
      tlSuccess.current
        .to("#lets-build form", { duration: 0.5, display: "none" })
        .to("#success", { display: "block" })
        .to("#success", {
          duration: 1,
          opacity: 1,
          scrambleText: {
            text: "We're sorry, there's been an error. Please send an email to letsbuild@kloveandsitch.com",
            chars: "!/&%{}]?#()",
          },
        });
    }
    return function cleanupListener() {
      try {
        tlLetsBuild.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, [submitted]);

  useLayoutEffect(() => {
    let parentElement = document.getElementById("form");
    let children = parentElement.childNodes;
    // backgroundColor: "#303030"
    tlLetsBuild.current
      .from(["#lets-build", "#footer"], { duration: 0.1, opacity: 0 })
      .from(children, {
        duration: 0.5,
        opacity: 0,
      })
      .to(["#form input", "#form textarea"], {
        duration: 0.1,
        opacity: 1,
        borderLeft: "1px solid #7c7c7c",
      })
      .to(["#form input", "#form textarea"], {
        duration: 0.1,
        opacity: 1,
        borderTop: "1px solid #7c7c7c",
        borderLeft: "none",
      })
      .to(["#form input", "#form textarea"], {
        duration: 0.1,
        opacity: 1,
        borderRight: "1px solid #7c7c7c",
        borderTop: "none",
      })
      .to(["#form input", "#form textarea"], {
        duration: 0.1,
        opacity: 1,
        borderBottom: "1px solid #7c7c7c",
        borderRight: "none",
      })
      .to(["#form input", "#form textarea"], {
        duration: 0.1,
        backgroundColor: "#1a1a1a",
        stagger: { each: 0.1 },
      });
    getStandAloneTimelines(tlLetsBuild.current, "tl-lets-build");
  }, []);
  return (
    <section
      id="lets-build"
      className={`section ${letsBuildCommonStyles}`}
      style={letsBuildStyles}
    >
      <h2 className={h2LetsBuildStyles}>
        Let's<span className={technoOrange}> Build</span>
      </h2>
      <Formik
        initialValues={{ name: "", email: "", company: "", message: "" }}
        validationSchema={Yup.object({
          name: Yup.string()
            .matches(
              /^[-'a-zA-Z]{4,}(?: [a-zA-Z]+){0,2}$/,
              "Invalid name entry"
            )
            .max(30, "Must be 30 characters or less")
            .required("* Required"),
          email: Yup.string()
            .email("Invalid email address")
            .required("* Required"),
          company: Yup.string()
            .max(40, "Must be 40 characters or less")
            .required("* Required"),
          message: Yup.string()
            .max(500, "Must be 500 characters or less")
            .required("* Required"),
        })}
        onSubmit={(values, { setSubmitting }) => {
          axios
            .post(
              "https://script.google.com/macros/s/AKfycbwuTXi2Q4N8XtaRp7Wq9lcHP1NsbMsRZrmVl8afqqN35NdOHVHsu3LPMesRH5ksE-Fe/exec",
              queryString.stringify(
                {
                  Name: values.name,
                  Email: values.email,
                  Company: values.company,
                  Message: values.message,
                },
                config
              )
            )
            .then((response) => {
              let status = response.status;

              if (status >= 200 && status < 300) {
                setSubmitted(true);
              } else {
                setSubmitted(false);
              }
            })
            .catch((error) => console.log("Network error: KS-13"));
          setSubmitting(false);
        }}
      >
        <Form id="form" className={`${form} ${formStyles}`}>
          <label className={formNameStyles} htmlFor="name">
            Name:
          </label>
          <Field className={formNameStyles} name="name" type="text" />
          <span className={`${errorStyles} ${nameError} ${technoOrange}`}>
            <ErrorMessage name="name" />
          </span>
          <label className={formEmailStyles} htmlFor="email">
            Company E-Mail:
          </label>
          <Field className={formEmailStyles} name="email" type="email" />
          <span className={`${errorStyles} ${emailError} ${technoOrange}`}>
            <ErrorMessage name="email" />
          </span>
          <label className={formCompanyStyles} htmlFor="company">
            Company:
          </label>
          <Field className={formCompanyStyles} name="company" type="text" />
          <span className={`${errorStyles} ${companyError} ${technoOrange}`}>
            <ErrorMessage name="company" />
          </span>
          <label className={formMessageStyles} htmlFor="message">
            Message:
          </label>
          <Field
            className={formMessageStyles}
            name="message"
            type="text"
            as="textarea"
          />
          <span className={`${errorStyles} ${messageError} ${technoOrange}`}>
            <ErrorMessage name="message" />
          </span>
          <button className={formButtonStyles} type="submit">
            Send It
          </button>
        </Form>
      </Formik>
      <h3 id="success" className={`${successMessage} ${h3InnerStyles}`}>
        Thank you!
        <br />
        We'll have our people contact your people.
      </h3>
    </section>
  );
}

export default LetsBuild;
