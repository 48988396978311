import React, { useRef, useLayoutEffect } from "react";
import gsap from "gsap";
import { ScrambleTextPlugin } from "gsap/ScrambleTextPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

import { technoOrange } from "../styles/global.module.css";
import {
  visionStyles,
  matrixLineStyles,
  matrixLine1,
  matrixLine2,
  matrixLine3,
  matrixLine4,
  matrixLine5,
  matrixLine6,
  matrixLine7,
  rectangleStyles,
  rectangle1Styles,
  rectangle2Styles,
  rectangle3Styles,
  rectangle4Styles,
  rectangle5Styles,
  rectangle6Styles,
  rectangle7Styles,
  rectangle8Styles,
  rectangle9Styles,
  rectangle10Styles,
  rectangle11Styles,
  rectangle12Styles,
  rectangle13Styles,
  rectangle14Styles,
  rectangle15Styles,
  binaryStyles,
  binaryScroller1Styles,
  binaryScroller2Styles,
  binaryScroller3Styles,
  binaryScroller4Styles,
  binaryScroller5Styles,
  binaryScroller6Styles,
  binaryScroller7Styles,
} from "../styles/vision-matrix.module.css";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrambleTextPlugin);
gsap.registerPlugin(ScrollToPlugin);

function VisionMatrix({ matrixTimeline, backgroundTimeline, children }) {
  const tlMatrix = useRef();
  const tlBackground = useRef();
  const tlRectangles = useRef();

  useLayoutEffect(() => {
    tlRectangles.current = gsap.timeline().to(".rectangle", {
      duration: 15,
      translateY: -600,
      ease: "power1.inOut",
      stagger: { each: 1 },
    });
    ScrollTrigger.create({
      trigger: "#content",
      start: "top 30%",
      toggleActions: "restart reverse restart reverse",
      // markers: true,
      animation: tlRectangles.current,
      scrub: 1,
    });

    tlMatrix.current = gsap.timeline().to(".binary-scroller", {
      duration: 1,
      scrambleText: { text: "110110010101011010100101011", chars: "01" },
      stagger: {
        each: 0.2,
      },
      marginTop: "15em",
      opacity: 0,
    });
    matrixTimeline(tlMatrix.current, "tl-matrix");

    tlBackground.current = gsap
      .timeline()
      .to(".vertical-line", { duration: 0.1, opacity: 0.05 })
      .from(".vertical-line", {
        duration: 0.6,
        height: 0,
        stagger: { each: 0.1 },
      })
      .to(".rectangle", {
        duration: 1,
        opacity: 1,
      });

    backgroundTimeline(tlBackground.current, "tl-matrix-background");

    return function cleanupListener() {
      try {
        tlMatrix.current.kill();
        tlBackground.current.kill();
        tlRectangles.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);
  return (
    <div id="vision-matrix" className={visionStyles}>
      {children}
      <svg
        id="column1"
        className={`vertical-line ${matrixLineStyles} ${matrixLine1}`}
        width="1"
        height="100%"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="0.5" y1="2.18557e-08" x2="0.5" y2="100%" stroke="white" />
      </svg>
      <svg
        id="column2"
        className={`vertical-line ${matrixLineStyles} ${matrixLine2}`}
        width="1"
        height="100%"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="0.5" y1="2.18557e-08" x2="0.5" y2="100%" stroke="white" />
      </svg>
      <svg
        id="column3"
        className={`vertical-line ${matrixLineStyles} ${matrixLine3}`}
        width="1"
        height="100%"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="0.5" y1="2.18557e-08" x2="0.5" y2="100%" stroke="white" />
      </svg>
      <svg
        id="column4"
        className={`vertical-line ${matrixLineStyles} ${matrixLine4}`}
        width="1"
        height="100%"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="0.5" y1="2.18557e-08" x2="0.5" y2="100%" stroke="white" />
      </svg>
      <svg
        id="column5"
        className={`vertical-line ${matrixLineStyles} ${matrixLine5}`}
        width="1"
        height="100%"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="0.5" y1="2.18557e-08" x2="0.5" y2="100%" stroke="white" />
      </svg>
      <svg
        id="column6"
        className={`vertical-line ${matrixLineStyles} ${matrixLine6}`}
        width="1"
        height="100%"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="0.5" y1="2.18557e-08" x2="0.5" y2="100%" stroke="white" />
      </svg>
      <svg
        id="column7"
        className={`vertical-line ${matrixLineStyles} ${matrixLine7}`}
        width="1"
        height="100%"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="0.5" y1="2.18557e-08" x2="0.5" y2="100%" stroke="white" />
      </svg>
      <svg
        width="302"
        height="301"
        viewBox="0 0 302 301"
        className={`rectangle ${rectangle1Styles} ${rectangleStyles}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="rectangle1">
          <rect
            className="dot"
            x="149.133"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="299.133"
            y="38.5894"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="148.512"
            y="298.755"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="1"
            y="255.187"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <path
            id="rect"
            opacity="0.1"
            d="M300.376 39.2118L149.754 0.622559L1.62109 256.432L149.754 300L300.376 39.2118Z"
            stroke="white"
          />
        </g>
      </svg>
      <svg
        width="302"
        height="301"
        viewBox="0 0 302 301"
        className={`rectangle ${rectangle2Styles} ${rectangleStyles}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="rectangle2">
          <rect
            className="dot"
            x="149.133"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="299.133"
            y="38.5894"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="148.512"
            y="298.755"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="1"
            y="255.187"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <path
            id="rect"
            opacity="0.1"
            d="M300.376 39.2118L149.754 0.622559L1.62109 256.432L149.754 300L300.376 39.2118Z"
            stroke="white"
          />
        </g>
      </svg>
      <svg
        id="rectangle3"
        width="300"
        height="301"
        className={`rectangle ${rectangle3Styles} ${rectangleStyles}`}
        viewBox="0 0 300 301"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M300 38.75L148.75 0L0 256.875L148.75 300.625L300 38.75Z"
          fill="white"
          fillOpacity="0.05"
        />
      </svg>
      <svg
        width="302"
        height="301"
        viewBox="0 0 302 301"
        className={`rectangle ${rectangle4Styles} ${rectangleStyles}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="rectangle4">
          <rect
            className="dot"
            x="149.133"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="299.133"
            y="38.5894"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="148.512"
            y="298.755"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="1"
            y="255.187"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <path
            id="rect"
            opacity="0.1"
            d="M300.376 39.2118L149.754 0.622559L1.62109 256.432L149.754 300L300.376 39.2118Z"
            stroke="white"
          />
        </g>
      </svg>
      <svg
        width="302"
        height="301"
        viewBox="0 0 302 301"
        className={`rectangle ${rectangle5Styles} ${rectangleStyles}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="rectangle5">
          <rect
            className="dot"
            x="149.133"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="299.133"
            y="38.5894"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="148.512"
            y="298.755"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="1"
            y="255.187"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <path
            id="rect"
            opacity="0.1"
            d="M300.376 39.2118L149.754 0.622559L1.62109 256.432L149.754 300L300.376 39.2118Z"
            stroke="white"
          />
        </g>
      </svg>
      <svg
        width="302"
        height="301"
        viewBox="0 0 302 301"
        className={`rectangle ${rectangle6Styles} ${rectangleStyles}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="rectangle6">
          <rect
            className="dot"
            x="149.133"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="299.133"
            y="38.5894"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="148.512"
            y="298.755"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="1"
            y="255.187"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <path
            id="rect"
            opacity="0.1"
            d="M300.376 39.2118L149.754 0.622559L1.62109 256.432L149.754 300L300.376 39.2118Z"
            stroke="white"
          />
        </g>
      </svg>
      <svg
        id="rectangle7"
        width="300"
        height="301"
        className={`rectangle ${rectangle7Styles} ${rectangleStyles}`}
        viewBox="0 0 300 301"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M300 38.75L148.75 0L0 256.875L148.75 300.625L300 38.75Z"
          fill="white"
          fillOpacity="0.05"
        />
      </svg>
      <svg
        width="302"
        height="301"
        viewBox="0 0 302 301"
        className={`rectangle ${rectangle8Styles} ${rectangleStyles}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="rectangle8">
          <rect
            className="dot"
            x="149.133"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="299.133"
            y="38.5894"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="148.512"
            y="298.755"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="1"
            y="255.187"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <path
            id="rect"
            opacity="0.1"
            d="M300.376 39.2118L149.754 0.622559L1.62109 256.432L149.754 300L300.376 39.2118Z"
            stroke="white"
          />
        </g>
      </svg>
      <svg
        id="rectangle9"
        width="300"
        height="301"
        className={`rectangle ${rectangle9Styles} ${rectangleStyles}`}
        viewBox="0 0 300 301"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M300 38.75L148.75 0L0 256.875L148.75 300.625L300 38.75Z"
          fill="white"
          fillOpacity="0.05"
        />
      </svg>
      <svg
        width="302"
        height="301"
        viewBox="0 0 302 301"
        className={`rectangle ${rectangle10Styles} ${rectangleStyles}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="rectangle10">
          <rect
            className="dot"
            x="149.133"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="299.133"
            y="38.5894"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="148.512"
            y="298.755"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="1"
            y="255.187"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <path
            id="rect"
            opacity="0.1"
            d="M300.376 39.2118L149.754 0.622559L1.62109 256.432L149.754 300L300.376 39.2118Z"
            stroke="white"
          />
        </g>
      </svg>
      <svg
        width="302"
        height="301"
        viewBox="0 0 302 301"
        className={`rectangle ${rectangle11Styles} ${rectangleStyles}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="rectangle11">
          <rect
            className="dot"
            x="149.133"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="299.133"
            y="38.5894"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="148.512"
            y="298.755"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="1"
            y="255.187"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <path
            id="rect"
            opacity="0.1"
            d="M300.376 39.2118L149.754 0.622559L1.62109 256.432L149.754 300L300.376 39.2118Z"
            stroke="white"
          />
        </g>
      </svg>
      <svg
        id="rectangle12"
        width="300"
        height="301"
        className={`rectangle ${rectangle12Styles} ${rectangleStyles}`}
        viewBox="0 0 300 301"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M300 38.75L148.75 0L0 256.875L148.75 300.625L300 38.75Z"
          fill="white"
          fillOpacity="0.05"
        />
      </svg>
      <svg
        width="302"
        height="301"
        viewBox="0 0 302 301"
        className={`rectangle ${rectangle13Styles} ${rectangleStyles}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="rectangle13">
          <rect
            className="dot"
            x="149.133"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="299.133"
            y="38.5894"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="148.512"
            y="298.755"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="1"
            y="255.187"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <path
            id="rect"
            opacity="0.1"
            d="M300.376 39.2118L149.754 0.622559L1.62109 256.432L149.754 300L300.376 39.2118Z"
            stroke="white"
          />
        </g>
      </svg>
      <svg
        width="302"
        height="301"
        viewBox="0 0 302 301"
        className={`rectangle ${rectangle14Styles} ${rectangleStyles}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="rectangle14">
          <rect
            className="dot"
            x="149.133"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="299.133"
            y="38.5894"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="148.512"
            y="298.755"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <rect
            className="dot"
            x="1"
            y="255.187"
            width="1.86722"
            height="1.86722"
            fill="#FC4D0D"
          />
          <path
            id="rect"
            opacity="0.1"
            d="M300.376 39.2118L149.754 0.622559L1.62109 256.432L149.754 300L300.376 39.2118Z"
            stroke="white"
          />
        </g>
      </svg>
      <svg
        id="rectangle15"
        width="300"
        height="301"
        className={`rectangle ${rectangle15Styles} ${rectangleStyles}`}
        viewBox="0 0 300 301"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M300 38.75L148.75 0L0 256.875L148.75 300.625L300 38.75Z"
          fill="white"
          fillOpacity="0.05"
        />
      </svg>
      <span
        className={`binary-scroller ${technoOrange} ${binaryStyles} ${binaryScroller1Styles}`}
      ></span>
      <span
        className={`binary-scroller ${technoOrange} ${binaryStyles} ${binaryScroller2Styles}`}
      ></span>
      <span
        className={`binary-scroller ${technoOrange} ${binaryStyles} ${binaryScroller3Styles}`}
      ></span>
      <span
        className={`binary-scroller ${technoOrange} ${binaryStyles} ${binaryScroller4Styles}`}
      ></span>
      <span
        className={`binary-scroller ${technoOrange} ${binaryStyles} ${binaryScroller5Styles}`}
      ></span>
      <span
        className={`binary-scroller ${technoOrange} ${binaryStyles} ${binaryScroller6Styles}`}
      ></span>
      <span
        className={`binary-scroller ${technoOrange} ${binaryStyles} ${binaryScroller7Styles}`}
      ></span>
    </div>
  );
}

export default VisionMatrix;
