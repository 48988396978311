import React, { useLayoutEffect, useRef } from "react";
import { scrollCueStyles, scrollText } from "../styles/scrollcue.module.css";

import gsap from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollToPlugin);

function ScrollCue({ getStandAloneTimelines, scrollTo }) {
  const tlScrollDown = useRef();

  useLayoutEffect(() => {
    // Check if window is defined (so if in the browser or in node.js).
    const isBrowser = typeof window !== "undefined";
    const scrollToButton = document.getElementById("scroll-cue");
    scrollToButton.addEventListener("click", scrollSection);

    function scrollSection() {
      if (isBrowser) {
        gsap.to(window, { duration: 1.5, scrollTo: `#${scrollTo}` });
      }
    }

    tlScrollDown.current = gsap
      .timeline()
      .to("#scroll-cue", { duration: 0.5, opacity: 1 }, "-=0.5")
      .to("#text", {
        rotation: 360,
        duration: 20,
        ease: "none",
        transformOrigin: "50% 50%",
        repeat: -1,
      });

    getStandAloneTimelines(tlScrollDown.current, "tl-scroll");

    return function cleanupListener() {
      scrollToButton.removeEventListener("click", scrollSection);

      try {
        tlScrollDown.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, []);

  return (
    <svg
      id="scroll-cue"
      className={scrollCueStyles}
      width="156"
      height="156"
      viewBox="0 0 156 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M75.141 70C73.559 70 72.754 70.169 72.013 70.565C71.395 70.8901 70.8901 71.395 70.565 72.013C70.169 72.753 70 73.559 70 75.14V80.858C70 82.44 70.169 83.245 70.565 83.986C70.902 84.616 71.383 85.097 72.013 85.434C72.753 85.83 73.559 85.999 75.141 85.999H76.859C78.441 85.999 79.246 85.83 79.987 85.434C80.605 85.1089 81.1098 84.604 81.435 83.986C81.831 83.246 82 82.44 82 80.858V75.14C82 73.558 81.831 72.753 81.435 72.012C81.1098 71.394 80.605 70.8891 79.987 70.564C79.247 70.169 78.441 70 76.86 70H75.14H75.141ZM75.141 68H76.859C78.873 68 79.953 68.278 80.931 68.801C81.8973 69.3125 82.6875 70.1027 83.199 71.069C83.722 72.047 84 73.127 84 75.141V80.859C84 82.873 83.722 83.953 83.199 84.931C82.6875 85.8973 81.8973 86.6875 80.931 87.199C79.953 87.722 78.873 88 76.859 88H75.14C73.126 88 72.046 87.722 71.068 87.199C70.1017 86.6875 69.3115 85.8973 68.8 84.931C68.278 83.953 68 82.873 68 80.859V75.14C68 73.126 68.278 72.046 68.801 71.068C69.3129 70.1019 70.1035 69.3121 71.07 68.801C72.047 68.278 73.127 68 75.141 68ZM75 72H77V77H75V72Z"
        fill="white"
      />
      <path
        id="text-circle"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 77.25C1.5 119.362 35.6383 153.5 77.75 153.5C119.862 153.5 154 119.362 154 77.25C154 35.1383 119.862 1 77.75 1C35.6383 1 1.5 35.1383 1.5 77.25ZM77.75 0C35.086 0 0.5 34.586 0.5 77.25C0.5 119.914 35.086 154.5 77.75 154.5C120.414 154.5 155 119.914 155 77.25C155 34.586 120.414 0 77.75 0Z"
        fill="#101010"
      />
      <text id="text">
        <textPath
          fill="#ffffff"
          xlinkHref="#text-circle"
          className={scrollText}
        >
          <tspan> · </tspan>
          scroll down
          <tspan> · </tspan>
          scroll down
          <tspan> · </tspan>
          scroll down
          <tspan> · </tspan>
          scroll down
        </textPath>
      </text>
    </svg>
  );
}

export default ScrollCue;
