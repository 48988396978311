import React, { useState, useLayoutEffect, useRef } from "react";
import { Link } from "gatsby";
import { gsap } from "gsap";

import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";

import { technoOrange, glitchStylesVideo } from "../styles/global.module.css";
import {
  sidebarStyles,
  menuStyles,
  socialNavStyles,
  socialAnchorStyles,
  glitchStyles,
  primaryMenuStyles,
  toggleStyles,
  activeStyles,
} from "../styles/sidebar.module.css";

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";
if (isBrowser) {
  gsap.registerPlugin(MorphSVGPlugin);
}

function Sidebar({ getStandAloneTimelines, userInterface }) {
  const tlSidebar = useRef();
  const tlMenu = useRef();
  const [isActive, setIsActive] = useState(true);
  const [activeLink, setActiveLink] = useState("hello");
  const clicked = useRef();
  const sidebar = useRef();

  tlMenu.current = gsap.timeline();
  tlSidebar.current = gsap.timeline();

  useLayoutEffect(() => {
    // Check if window is defined (so if in the browser or in node.js).
    const isBrowser = typeof window !== "undefined";
    const letsBuildLink = document.getElementById("lets-build-link");
    letsBuildLink.addEventListener("click", scrollSection);

    function scrollSection() {
      sidebar.current = document.getElementById("sidebar");
      sidebar.current.classList.add("inactive");
      sidebar.current.classList.remove("active");
      removeMenu();
      if (isBrowser) {
        gsap.to(window, { duration: 1.5, scrollTo: `#lets-build` });
      }
      setIsActive(!isActive);
    }

    switch (userInterface) {
      case "hello":
        setActiveLink("hello");
        break;
      case "design":
        setActiveLink("design");
        break;
      case "development":
        setActiveLink("development");
        break;
    }

    gsap.set(".active-link", {
      backgroundColor: "#131313",
    });
    gsap.set(".inactive-link", {
      backgroundColor: "transparent",
    });
    gsap.set(".inactive-link a", { color: "#ffffff" });
    gsap.set(".active-link a", { color: "#fc4d0d" });

    gsap.set("#sidebar .glitch-video", { display: "none" });

    const onClick = () => {
      setIsActive(!isActive);
      if (isActive) {
        loadMenu();
      } else {
        removeMenu();
      }
    };

    clicked.current = onClick;

    function loadMenu() {
      tlMenu.current
        .to(`.toggle`, {
          duration: 0.1,
          morphSVG:
            "M77.0903 11.5032L0.621094 0L0.249206 2.47219L67.8432 12.6403L0 20.9829L0.305125 23.4642L77.0563 14.0262L153.525 25.5293L153.897 23.0571L86.3033 12.8891L154.146 4.54648L153.841 2.06517L77.0903 11.5032Z",
        })
        .to(
          `.active`,
          { duration: 0.1, backgroundColor: "#101010", zIndex: 2 },
          "-=0.1"
        )
        .to(`.active`, {
          duration: 0.2,
          width: "22.5em",
          height: "100vh",
        })
        .to(
          `.active .glitch-video`,
          {
            duration: 0.2,
            display: "block",
          },
          "-=0.2"
        )
        .to(`.active .glitch-video`, {
          duration: 0.1,
          display: "none",
        })
        .to(".active", {
          duration: 0.1,
          backgroundColor: "#1A1A1A",
          opacity: 0.97,
        })
        .to(
          "#primary-menu-nav",
          { duration: 0.1, padding: "3em 2em 0 2.5em", display: "block" },
          "-=0.1"
        )
        .to(".link", { duration: 0.1, padding: "0.7em" }, "-=0.1")
        .to(".primary-menu", { duration: 0.1, display: "block" })
        .to("#social-nav", {
          duration: 0.5,
          flexDirection: "row",
          columnGap: "4em",
          padding: "0em 3.5em 0em 0em",
        });
    }

    function removeMenu() {
      const mediaQueriesSB = [
        "(min-width: 1031px)",
        "(max-width: 1030px)",
        "(max-width: 650px)",
        "(max-width: 575px)",
        "(max-width: 480px)",
      ];
      let navRightPadding;

      if (isBrowser) {
        let mqlSB;
        mediaQueriesSB.forEach((querySB) => {
          mqlSB = window.matchMedia(querySB);
          if (mqlSB.matches) {
            switch (querySB) {
              case "(max-width: 1030px)":
                navRightPadding = 5;
                break;
              case "(max-width: 650px)":
                navRightPadding = 4;
                break;
              case "(max-width: 575px)":
                navRightPadding = 3;
                break;
              case "(max-width: 480px)":
                navRightPadding = 1;
                break;
              case "(min-width: 1031px)":
                navRightPadding = 6;
                break;
            }
          }
        });
      }

      gsap.killTweensOf(`.primary-menu`);
      tlMenu.current
        .to(`.toggle`, {
          duration: 0.1,
          morphSVG: "M0 0H155V2.5H0V0ZM0 13.25H77.5H155V15.75H0V13.25Z",
        })
        .to(".primary-menu", { duration: 0.1, display: "none" }, "-=0.1")
        .to("#primary-menu-nav", { duration: 0.1, padding: "0" })
        .to(".link", { duration: 0.1, padding: "0" }, "-=0.1")
        .to(`.inactive`, { duration: 0.1, backgroundColor: "#101010" })
        .to(`.inactive`, {
          duration: 0.2,
          width: "11.2em",
          height: "100vh",
        })
        .to(
          "#social-nav",
          {
            duration: 0.5,
            flexDirection: "column",
            padding: `0em ${navRightPadding}em 0em 0em`,
          },
          "-=0.2"
        )
        .to(
          `.inactive .glitch-video`,
          {
            duration: 0.2,
            display: "block",
          },
          "-=0.2"
        )
        .to(`.inactive .glitch-video`, {
          duration: 0.1,
          display: "none",
        })
        .to(".inactive", {
          duration: 0.1,
          backgroundColor: "transparent",
          opacity: 1,
          borderLeft: "none",
          zIndex: 0,
        });
    }

    return function cleanupListener() {
      letsBuildLink.removeEventListener("click", scrollSection);
      try {
        tlSidebar.current.kill();
        tlMenu.current.kill();
      } catch (e) {
        // Code to run if an exception (error) occurs
      }
    };
  }, [isActive]);

  useLayoutEffect(() => {
    tlSidebar.current
      .from("#menu-nav", {
        duration: 0.2,
        opacity: 0,
        repeat: 3,
      })
      .from("#social-nav a", {
        duration: 0.9,
        opacity: 0,
        stagger: { each: 0.3 },
      });

    getStandAloneTimelines(tlSidebar.current, "tl-sidebar");
  }, []);

  return (
    <React.Fragment>
      <main
        id="sidebar"
        className={`${isActive ? "active" : "inactive"} ${sidebarStyles}`}
      >
        <video
          className={`glitch-video ${glitchStylesVideo} ${glitchStyles}`}
          src="/glitch.mp4"
          autoPlay
          muted
          playsInline
          loop
        ></video>
        <svg
          id="menu-nav"
          className={`${toggleStyles}`}
          onClick={() => clicked.current()}
          width="100"
          height="27"
          viewBox="0 0 155 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className={`toggle`}
            d="M0 0H155V2.5H0V0ZM0 13.25H77.5H155V15.75H0V13.25Z"
            fill="white"
          />
        </svg>
        <nav id="primary-menu-nav" className={menuStyles}>
          <ul className={`primary-menu ${primaryMenuStyles}`}>
            <li
              className={
                `${activeLink}` === "hello"
                  ? `active-link link ${activeStyles} ${technoOrange}`
                  : "inactive-link link"
              }
            >
              <Link to="/">Hello</Link>
            </li>
            <li
              className={
                `${activeLink}` === "design"
                  ? `active-link link ${activeStyles} ${technoOrange}`
                  : "inactive-link link"
              }
            >
              <Link to="/design-process">Our Design Process</Link>
            </li>
            <li
              className={
                `${activeLink}` === "development"
                  ? `active-link link ${activeStyles} ${technoOrange}`
                  : "inactive-link link"
              }
            >
              <Link to="/development-process">Our Development Process</Link>
            </li>
            <li id="lets-build-link" className="link inactive-link">
              <Link to="#lets-build">Let's Build</Link>
            </li>
          </ul>
        </nav>
        <nav id="social-nav" className={socialNavStyles}>
          <a
            href="https://www.facebook.com/Klove-Sitch-Agency-105289275406646"
            target="_blank"
            rel="noopener noreferrer"
            className={socialAnchorStyles}
          >
            fb<span className={technoOrange}>.</span>
          </a>
          <a
            href="https://twitter.com/KloveandSitch"
            target="_blank"
            rel="noopener noreferrer"
            className={socialAnchorStyles}
          >
            tw<span className={technoOrange}>.</span>
          </a>
          <a
            href="https://kloveandsitch.medium.com/"
            target="_blank"
            rel="noopener noreferrer"
            className={socialAnchorStyles}
          >
            md<span className={technoOrange}>.</span>
          </a>
        </nav>
      </main>
    </React.Fragment>
  );
}

export default Sidebar;
